import * as React from 'react'
import styles from './styles.module.scss'
import { useAppStore } from '../../../storage'
import { selectPlan } from '../../../helpers/api'
import { isMobile } from 'react-device-detect'
// import { getProfiles } from '../../../helpers/api'

const SubscriptionsTab: React.FC = () => {
  const { plansData, selectedPlan } = useAppStore()
  // const [selectedPlan, setSelectedPlan] = React.useState(0)

  const handleButtonClick = (id: number): void => {
    // setSelectedPlan(id)
    if (selectedPlan === id) return
    selectPlan(id)
      .then((data) => {
        if (data.data.status === 1) {
          useAppStore.setState({
            selectedPlan: id
            // Number(data.data.data.current_plan_id)
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <div className={`${styles.tab_wrapper} ${isMobile ? styles.mobile : ''}`}>
      <div className={styles.buttons_wrapper}>
        <div className={styles.title}>Manage Subscriptions</div>
        {plansData.map((el: any) => {
          return (
            <div
              className={`${styles.button_wrapper} ${
                selectedPlan === el.id ? styles.active : null
              }`}
              key={el.id}
              onClick={() => {
                handleButtonClick(el.id)
              }}
            >
              <div className={styles.radio_bttn}>
                <div className={styles.circle} />
              </div>
              <div className={styles.button_card}>
                <div className={styles.button_label}>{el.title}</div>
                <div className={styles.value_row}>
                  Amount <span className={styles.amount}>${el.unit_price}</span>
                </div>
                <div className={styles.manage_account_bttn}>
                  Manage My Account
                  <img
                    src="./assets/images/Card.svg"
                    className={styles.icon}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SubscriptionsTab
