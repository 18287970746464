import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'

interface ICustomTableProps {
  headers: string[]
  cells: (JSX.Element | string | number)[][]
  markedArr?: number[]
  type?: string
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(19, 27, 74, 1)'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '&:hover': {
    backgroundColor: 'rgba(57, 52, 195, 1) !important'
  }
}))

const CustomTable: React.FC<ICustomTableProps> = ({
  headers,
  cells,
  markedArr,
  type = 'default'
}) => {
  return (
    <TableContainer
      component={Paper}
      className={`${styles.table_container} ${styles[type]}`}
    >
      <Table
        // sx={{ minWidth: 100 }}
        aria-label="customized table"
        className={styles.table}
      >
        <TableHead className={styles.table_head}>
          <TableRow className={styles.table_row}>
            {/* <StyledTableCell>Dessert (100g serving)</StyledTableCell>
            <StyledTableCell align="right">Calories</StyledTableCell>
            <StyledTableCell align="right">Fat&nbsp;(g)</StyledTableCell>
            <StyledTableCell align="right">Carbs&nbsp;(g)</StyledTableCell>
            <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
            {headers.map((el, index) => {
              return (
                <TableCell
                  className={`${styles.table_cell} ${styles.table_cell_h}`}
                  key={index}
                >
                  {el}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))} */}
          {cells.map((row, rowInd) => {
            return (
              <StyledTableRow
                key={row[0] as string}
                className={styles.table_row}
              >
                {row.map((cell, cellIndex) => {
                  return (
                    <TableCell
                      key={cellIndex.toFixed() + '_' + rowInd.toFixed()}
                      className={styles.table_cell}
                    >
                      {cell}
                    </TableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomTable
