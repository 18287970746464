import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
// import { red, green, blue } from '@mui/material/colors'

export const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: '1.9em',
  fontFamily: 'EB Garamond',
  padding: '0.3em 0.3em 0.3em 0.3em',
  background: 'rgba(9, 12, 43, 0.7)',
  color: 'white',
  border: '2px solid rgba(0, 0, 0, 0.7)',
  boxShadow: '2px 4px 4px 0px rgba(58, 53, 197, 0.7)',
  textTransform: 'capitalize',
  margin: 0,

  marginTop: '0.5em !important',
  marginBottom: '0.2em !important',
  lineHeight: 'normal !important',
  ':hover': {
    background: 'rgba(58, 53, 197, 0.7)',
    boxShadow: '2px 4px 4px 0px rgba(58, 53, 197, 0.7)'
  },
  '@media screen and (max-width: 800px)': {
    fontSize: '2.8em',
    padding: '0.5em 0.3em 0.5em 0.3em',
    borderRadius: '8px',
    marginTop: '1em !important'
  }
  // padding: theme.spacing(1),
  // [theme.breakpoints.down('md')]: {
  //   backgroundColor: red[500]
  // },
  // [theme.breakpoints.up('md')]: {
  //   backgroundColor: blue[500]
  // },
  // [theme.breakpoints.up('lg')]: {
  //   backgroundColor: green[500]
  // }
  // '.MuiInputBase-input': {
  //   color: 'white',
  //   fontSize: '35px',
  //   lineHeight: '45.5px',
  //   margin: '14px 0',
  //   fontFamily: 'EB Garamond'
  // },
  // '.MuiInputLabel-root': {
  //   color: 'white',
  //   fontSize: '25px',
  //   lineHeight: '45.5px',
  //   fontFamily: 'EB Garamond'
  // },
  // '.MuiInput-underline:after': {
  //   borderBottomColor: '#3A35C5',
  //   borderWidth: '3px'
  // },
  // '.MuiInput-root:before': {
  //   borderBottomColor: '#2f44bd',
  //   borderWidth: '3px'
  // },
  // '.MuiInput-underline:hover:after': {
  //   borderBottomColor: '#3A35C5',
  //   opacity: 0.7
  // },
  // '.MuiInput-root:hover:before': {
  //   borderBottomColor: '#2f44bd',
  //   opacity: 0.5
  // }
})) as typeof Button
