import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import { lodash as _ } from 'lodash'

interface ICustomSelectProps {
  onChangeCallback?: (e: SelectChangeEvent<unknown>) => void
  list: string[]
  defaulValue: string
  value: number | null
  variant?: number
}

// const StyledInput = styled(InputBase)(({ theme }) => ({
//   // 'label + &': {
//   //   marginTop: theme.spacing(3)
//   // },
//   '& .MuiInputBase-input': {
//     position: 'relative',
//     borderRadius: '8px',
//     background: 'linear-gradient(0deg, #131B4A 0%, #131B4A 100%)',
//     border: '1px solid #ced4da',
//     padding: '8px 16px',
//     // Use the system font instead of the default Roboto font.
//     color: 'white',
//     fontFamily: 'Lato',
//     fontSize: '16px',
//     fontStyle: 'normal',
//     fontWeight: 500,
//     lineHeight: 'normal',
//     '&:focus': {}
//   }
// }))
// const StyledInput = styled(InputBase)(({ theme }) => ({
//   'label + &': {
//     marginTop: theme.spacing(3)
//   },
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: 16,
//     padding: '10px 26px 10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"'
//     ].join(','),
//     '&:focus': {
//       borderRadius: 4,
//       borderColor: '#80bdff',
//       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
//     }
//   }
// }))

const CustomSelect: React.FC<ICustomSelectProps> = ({
  list,
  onChangeCallback,
  defaulValue,
  value,
  variant
}) => {
  const renderChilds = (): JSX.Element[] => {
    const rows = list.map((item, index) => {
      return (
        <MenuItem key={index} value={index}>
          {item}
        </MenuItem>
      )
    })
    return rows
  }
  const additionalOptions =
    variant === 2
      ? {
          paddingLeft: 0
        }
      : {}
  return (
    <Select
      value={value}
      onChange={onChangeCallback}
      // input={<StyledInput />}
      className={`${styles.custom_select} ${
        variant === 2 ? styles.blue_variant : ''
      }`}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: variant === 2 ? 'none !important' : ''
        },
        '@media screen and (max-width: 800px)': {
          '.MuiOutlinedInput-input': {
            paddingRight: '5px !important',
            ...additionalOptions
          }
        }
      }}
      IconComponent={() => (
        <KeyboardArrowDownIcon
          className={styles.icon}
          style={{
            color: variant === 2 ? 'rgba(39, 110, 192, 1)' : 'white'
          }}
        />
      )}
    >
      {renderChilds()}
    </Select>
  )
}

export default CustomSelect
