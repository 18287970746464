import axios from 'axios'
// import { later } from '..'
// import {
//   dummyData
//   // , dummyUserToken
// } from '../../constants/dummy.data/user'
// import { type ServerResponseData } from '../../types'
import {
  BACKEND_API_URL
  // LOGIN
} from '../../constants/api.constants'
import { useAppStore } from '../../storage'
import { getUserInfo } from '..'

axios.defaults.baseURL = BACKEND_API_URL

export const _setBearer = (): void => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    Accept: 'application/json'
  }
}

export const getDashboardCards = async (): Promise<any> => {
  const user = getUserInfo()
  // console.log('user info: ', user)
  if (user) {
    return axios.post('/dashboard/cards', {
      account_id: user.account_id
    })
  }
}
export const getUser = async (): Promise<any> => {
  // return axios.get('info', {})
  return axios.get('/account/user', {})
}
export const getTransactions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/account/transactions/${accountID}`)
}
export const getSubscriptions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/subscriptions/show/${accountID}`)
}
export const getHoldings = async (
  accountID?: number,
  securityID?: string
): Promise<any> => {
  return axios.post('/holding/search', {
    where: [
      ['status', 'active'],
      ['account_id', accountID]
    ]
    // account_id: accountID,
    // security_id: securityID,
    // status: 'active'
  })
}

export const getSecurities = async (securityID?: string): Promise<any> => {
  return axios.post('/security/search', { // ?status=active', {
    // params: {
    //   security_number: securityNum
    // }
    page: 1,
    limit: 10,
    fields: [
      "id","security_number","stock_price","type","put","straddle","total_price","expected_value","simulations","status","created_at"
    ],
    where: [
      ['status', 'active']
    ]
  })
}

export const getMDFTransactions = async (
  accountID: string | number
): Promise<any> => {
  return axios.get(`/account/transactions/${accountID}/?type=mdf`)
}

export const getMonthlyPayout = async (): Promise<any> => {
  return axios.get('/investor/table/month-pay')
}
export const getRanks = async (): Promise<any> => {
  return axios.get('/investor/table/ranks')
}
export const getPlans = async (): Promise<any> => {
  return axios.post('/plans/search')
}

export const simulateSecurity = async (
  holdingID: number,
  securityID: number,
  securityNumber: number,
  simsNum?: number,
  simAll?: number
): Promise<any> => {
  return axios.post('/investor/security/simulate', {
    sim: simsNum ?? 1,
    simAll: simAll ?? 0,
    security: {
      id: securityID,
      security_number: securityNumber,
      holding_id: holdingID
    }
  })
}

export const sellHolding = async (
  holdingID: number,
  accountID: number
): Promise<any> => {
  return axios.post('/investor/holding/sell', {
    id: holdingID,
    account_id: accountID
  })
}

export const buySecurity = async (
  securityID: number,
  accountID: number
): Promise<any> => {
  return axios.post('/investor/security/buy', {
    id: securityID,
    account_id: accountID
  })
}

export const getInvestorGraphInfo = async (
  holdingID: number,
  securityNumber: number
): Promise<any> => {
  return axios.post('/investor/graph/info', {
    security_number: securityNumber,
    holding_id: holdingID
  })
}
export const getInvestorTableInfo = async (
  securityNumber: number
): Promise<any> => {
  return axios.get('/investor/table/info', {
    params: {
      security_number: securityNumber
    }
  })
}

export const createStripeAccount = async (
  firstname: string,
  lastname: string,
  email: string
): Promise<any> => {
  const data = {
    owner_type: 'user',
    name: firstname + ' ' + lastname,
    email: email,
    type: 'individual'
  }

  return axios.post('/my/payment/profile', data)
}

export const selectPlan = async (id: string | number): Promise<any> => {
  return axios.get(`/plans/select/${id}`)
}

export const getProfiles = async (): Promise<any> => {
  return axios.get('/profiles')
}

export const simulateHolding = async (
  simulatedHolding: any,
  simsNum?: number,
  simAll?: number
): Promise<any> => {
  await simulateSecurity(
    simulatedHolding.id,
    simulatedHolding.security.id,
    simulatedHolding.security.security_number,
    simsNum,
    simAll
  )
    .then((simRespData) => {
      useAppStore.setState({ simulationData: simRespData.data.data })

      getInvestorTableInfo(simulatedHolding.security.security_number)
        .then((respData) => {
          useAppStore.setState({
            simulationHistoryData: respData.data
          })
        })
        .catch((e) => {
          console.error(e)
        })
      getInvestorGraphInfo(
        simulatedHolding.id,
        simulatedHolding.security.security_number
      )
        .then((respData) => {
          useAppStore.setState({
            simulationGraphData: respData.data.data
          })
        })
        .catch((e) => {
          console.error(e)
        })
    })
    .catch((e) => {
      console.error(e)
    })
  // return axios.get('/investor/table/ranks')
}

export const getData = async (token?: string): Promise<any> => {
  // console.log('fetching data.....')
  const dashboardInfo = (await getDashboardCards()).data?.data
  // console.log('dashboard info: ', dashboardInfo)

  if (dashboardInfo) {
    const cardsData = dashboardInfo?.data
    const accountID = dashboardInfo?.account_id

    useAppStore.setState({ cardsData, accountID })

    getTransactions(accountID)
      .then((data) => {
        // console.log('transactions: ', data.data.data.transactions)
        useAppStore.setState({
          transactionsData: data.data.data.transactions.data
        })
        useAppStore.setState({ mdfData: data.data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    // getSubscriptions(accountID)
    //   .then((data) => {
    //     // useAppStore.setState({ transactionsData: data.data.data })

    //     useAppStore.setState({ selectedPlan: data.data.data.current_plan_id })
    //   })
    //   .catch((e) => {
    //     console.error(e)
    //   })
    getUser()
      .then((data) => {
        useAppStore.setState({ selectedPlan: data.data.data.plan_id })
      })
      .catch((e) => {
        console.error(e)
      })
    // getMDFTransactions(accountID)
    //   .then((data) => {
    //     useAppStore.setState({ mdfData: data.data.data })
    //   })
    //   .catch((e) => {
    //     console.error(e)
    //   })
    getHoldings(accountID)
      .then((data) => {
        useAppStore.setState({ holdingsData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getSecurities()
      .then((data) => {
        useAppStore.setState({ securitiesData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getMonthlyPayout()
      .then((data) => {
        useAppStore.setState({ monthlyPayoutData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })
    getRanks()
      .then((data) => {
        useAppStore.setState({ rankingsData: data.data })
      })
      .catch((e) => {
        console.error(e)
      })

    getPlans()
      .then((data) => {
        useAppStore.setState({ plansData: data.data.data })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // return later(100, dummyData)
  // return await later(100, dummyData)
}

export const loginUser = async (
  name: string,
  password: string
): Promise<any> => {
  return await axios.post(
    // 'https://nvi.swellmadeit.com/api/login',
    '/login',
    {
      email: name,
      password: password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': ' ',
        Accept: 'application/json'
      }
    }
  )

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}

export const updateUser = async (data: {
  firstname?: string
  lastname?: string
  email?: string
  country?: string
  state?: string
  password?: string
  avatarFile?: any
}): Promise<any> => {
  const formData = new FormData()
  data.firstname && formData.append('firstname', data.firstname)
  data.lastname && formData.append('lastname', data.lastname)
  data.email && formData.append('email', data.email)
  data.country && formData.append('country', data.country)
  data.state && formData.append('state', data.state)
  data.avatarFile && formData.append('avatarFile', data.avatarFile)
  if (data.password) {
    formData.append('password', data.password)
    formData.append('password_confirmation', data.password)
  }
  return await axios.post('/investor/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-CSRF-TOKEN': ' ',
      Accept: 'application/json'
    }
  })

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}
export const registerUser = async (
  name: string,
  email: string,
  password: string
): Promise<any> => {
  return await axios.post(
    '/register',
    {
      firstname: name,
      lastname: name,
      email,
      password,
      password_confirmation: password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': ' ',
        Accept: 'application/json'
      }
    }
  )

  // return await axios.get(BACKEND_API_URL + '/holding/search', {
  //   headers: {
  //     Authorization: 'Bearer 7|z8AVZwl32Gvl4u4SplXfj3F9HQEIhdDpeRnQLINi'
  //     // 'Content-Type': 'application/json',
  //     // 'X-CSRF-TOKEN': '',
  //     // accept: 'application/json'
  //   }
  // })
  // if (name === 'bjack@gmail.com' && password === 'bjack123') {
  //   return await later(100, dummyUserToken)
  // } else {
  //   throw Error('Wrong credentials')
  // }
}
