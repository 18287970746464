import { TRANSACTION_COLORS } from '../constants'

export const validateEmail = (email: string): RegExpMatchArray | null => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const later = async <T>(delay: number, value?: T): Promise<T> => {
  return await new Promise(function (resolve) {
    setTimeout(resolve, delay, value)
  })
}

export const getUserToken = (): string | null => {
  return window.localStorage.getItem('userToken')
}

export const getUserInfo = (): any | null => {
  const userInfo = window.localStorage.getItem('userInfo')
  if (userInfo) {
    return JSON.parse(userInfo)
  }
  return null
}

const NEGATIVE_TRANSACTIONS = ['buy', 'weekly subscription']

export const getTransactionSign = (t: any): number => {
  if (NEGATIVE_TRANSACTIONS.includes(t.operation)) return -1
  return 1
}

export const getTransactionSignChar = (t: any): string => {
  if (NEGATIVE_TRANSACTIONS.includes(t.operation)) return '-'
  return '+'
}

export const getTransationColor = (item: any): { primary: string } => {
  // const color = TRANSACTION_COLORS[item.type]

  if (NEGATIVE_TRANSACTIONS.includes(item)) {
    return TRANSACTION_COLORS[1]
  } else {
    return TRANSACTION_COLORS[0]
  }
  // if (color) {
  //   return color
  // } else {
  //   return TRANSACTION_COLORS[0]
  // }
}

export const getTransationIcon = (type: string | number): string => {
  if (type === 'mdf') {
    return '/assets/images/Transaction4.svg'
  } else if (type === 'withdrawal') {
    return '/assets/images/Transaction4.svg'
  } else if (
    type === 'buy' ||
    type === 'weekly subscription' ||
    type === 'monthly subscription'
  ) {
    return '/assets/images/Transaction2.svg'
  }

  return '/assets/images/Transaction1.svg'
  // if (color) {
  //   return color
  // } else {
  //   return TRANSACTION_COLORS[0]
  // }
}
