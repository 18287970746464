import { Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useAppStore } from '../../storage'
import { useNavigate } from 'react-router-dom'
// import LogoBackground from '../../components/logo.background'
import { CustomButton } from '../../components/custom.button'
import { ROUTES } from '../../app.router/router.constants'
import UserForm from './user.form'
import { BACKEND_URL } from '../../constants/api.constants'
import PaymentMetodTab from './payment'
import SubscriptionsTab from './subscriptions'
import { isMobile } from 'react-device-detect'

const ProfilePage: React.FC = () => {
  const { userData } = useAppStore()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<number | null>(isMobile ? null : 0)
  const [imgFile, setImgFile] = useState(null)
  const [newImgSrc, setNewImgSrc] = useState('')

  const handleBackClick = (): void => {
    if (!isMobile || activeTab === null) navigate(ROUTES.Main)
    else {
      setActiveTab(null)
    }
  }

  const handleTabButtonClick = (i: number): void => {
    setActiveTab(i)
  }

  const renderTabContent = (i: number | null): JSX.Element | undefined => {
    switch (i) {
      case 0:
        return <UserForm userData={userData} imgFile={imgFile} />
      case 1:
        return <PaymentMetodTab />
      case 2:
        return <SubscriptionsTab />
    }
  }

  const handlePhotoChange = (e: any): void => {
    if (e.target.files?.length) {
      setImgFile(e.target.files[0])
      setNewImgSrc(window.URL.createObjectURL(e.target.files[0]))
    }
  }

  // console.log('profile user data: ', userData)
  return (
    <>
      {/* <LogoBackground /> */}
      {userData ? (
        <Grid
          className={`${styles.profile_page_wrapper} ${
            isMobile ? styles.mobile : null
          }`}
        >
          <IconButton onClick={handleBackClick} className={styles.back_button}>
            <img src="/assets/images/Back.svg" />
          </IconButton>
          {!isMobile ? (
            <>
              <Grid className={styles.left_side}>
                <Grid className={styles.user_photo_name}>
                  <label
                    htmlFor="imgInp"
                    className={styles.user_photo_container}
                  >
                    {/* <img src="/assets/images/user_photo.jpg" /> */}
                    <input
                      style={{ display: 'none' }}
                      accept="image/*"
                      type="file"
                      id="imgInp"
                      onChange={handlePhotoChange}
                    />
                    {newImgSrc || userData?.avatar ? (
                      <img
                        src={
                          newImgSrc ||
                          BACKEND_URL + userData?.avatar?.toString()
                        }
                      />
                    ) : null}
                  </label>
                  <Grid className={styles.user_label}>
                    {userData.firstname}
                  </Grid>
                </Grid>
                <Grid className={styles.buttons}>
                  <CustomButton
                    onClick={() => {
                      handleTabButtonClick(0)
                    }}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      background:
                        activeTab === 0
                          ? 'rgba(58, 53, 197, 0.7)'
                          : 'rgba(9, 12, 43, 0.7)'
                    }}
                  >
                    General Information
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      handleTabButtonClick(1)
                    }}
                    // type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      background:
                        activeTab === 1
                          ? 'rgba(58, 53, 197, 0.7)'
                          : 'rgba(9, 12, 43, 0.7)'
                    }}
                  >
                    Payment Method
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      handleTabButtonClick(2)
                    }}
                    // type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      background:
                        activeTab === 2
                          ? 'rgba(58, 53, 197, 0.7)'
                          : 'rgba(9, 12, 43, 0.7)'
                    }}
                  >
                    Manage Subscriptions
                  </CustomButton>
                </Grid>
              </Grid>
              <Grid className={styles.right_side}>
                {renderTabContent(activeTab)}
              </Grid>
            </>
          ) : (
            <>
              {activeTab !== null ? (
                <Grid className={styles.right_side}>
                  {renderTabContent(activeTab)}
                </Grid>
              ) : (
                <Grid className={styles.left_side}>
                  <Grid className={styles.user_photo_name}>
                    <label
                      htmlFor="imgInp"
                      className={styles.user_photo_container}
                    >
                      {/* <img src="/assets/images/user_photo.jpg" /> */}
                      <input
                        style={{ display: 'none' }}
                        accept="image/*"
                        type="file"
                        id="imgInp"
                        onChange={handlePhotoChange}
                      />
                      {newImgSrc || userData?.avatar ? (
                        <img
                          src={
                            newImgSrc ||
                            BACKEND_URL + userData?.avatar?.toString()
                          }
                        />
                      ) : null}
                    </label>
                    <Grid className={styles.user_label}>
                      {userData.firstname}
                    </Grid>
                  </Grid>
                  <Grid className={styles.buttons}>
                    <CustomButton
                      onClick={() => {
                        handleTabButtonClick(0)
                      }}
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        background:
                          activeTab === 0
                            ? 'rgba(58, 53, 197, 0.7)'
                            : 'rgba(9, 12, 43, 0.7)'
                      }}
                    >
                      General Information
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        handleTabButtonClick(1)
                      }}
                      // type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        background:
                          activeTab === 1
                            ? 'rgba(58, 53, 197, 0.7)'
                            : 'rgba(9, 12, 43, 0.7)'
                      }}
                    >
                      Payment Method
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        handleTabButtonClick(2)
                      }}
                      // type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        background:
                          activeTab === 2
                            ? 'rgba(58, 53, 197, 0.7)'
                            : 'rgba(9, 12, 43, 0.7)'
                      }}
                    >
                      Manage Subscriptions
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      ) : null}
    </>
  )
}

export default ProfilePage
