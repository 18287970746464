import { Grid } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss'
import { PagesRoutes } from '../app.router'
import LogoBackground from '../components/logo.background'

const MainLayout: React.FC = () => {
  return (
    <Grid id="mainLayout" className={styles.mainLayout}>
      <div className={styles.logo_bg_wrapper}>
        <LogoBackground />
      </div>
      <PagesRoutes />
    </Grid>
  )
}
export default MainLayout
