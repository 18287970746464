import * as React from 'react'
import styles from './styles.module.scss'
import { createStripeAccount, getProfiles } from '../../../helpers/api'
import { isMobile } from 'react-device-detect'
import { useAppStore } from '../../../storage'

const PaymentMetodTab: React.FC = () => {
  // 'https://connect.stripe.com/express_login'
  const { userData } = useAppStore()
  const [stripeLoginUrl, setStripeLoginUrl] = React.useState('')
  const [profile, setProfile] = React.useState(null)

  const handleClick = (): void => {
    console.log('payment clicked!')
    if (userData) {
      createStripeAccount(userData?.firstname, userData?.lastname, userData.email)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            setStripeLoginUrl(response.data.data)
            window.open(response.data.data, '_blank')
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  const profilesRequest = async (): Promise<void> => {
    getProfiles()
      .then((data) => {
        const response = data.data
        console.log('profile: ', response)
        if (response?.status === 1) {
          setStripeLoginUrl(response.data.login ? response.data.login.url : '')
          setProfile(response.data)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  React.useEffect(() => {
    void profilesRequest()
  }, [])

  return (
    <div className={`${styles.tab_wrapper} ${isMobile ? styles.mobile : ''}`}>
      {/* <div
        className={styles.test}
        onClick={handleClick}
        style={{ color: 'red' }}
      >
        1111
      </div> */}

      {(stripeLoginUrl.length > 0) ? <a
        href={stripeLoginUrl}
        target="_blank"
        className={styles.manage_account_bttn}
        rel="noreferrer"
      >
        <div className={styles.label}>Manage My Account:</div>
        <img src="./assets/images/Card.svg" className={styles.icon} alt="" />
      </a>
        : <a
          className={styles.manage_account_bttn}
          rel="noreferrer"
          onClick={() => {
            handleClick()
          }}
        >
          <div className={styles.label}>Manage My Account:</div>
          <img src="./assets/images/Card.svg" className={styles.icon} alt="" />
        </a>}
    </div>
  )
}

export default PaymentMetodTab
