import { Box, Grid, InputAdornment } from '@mui/material'
import * as React from 'react'
import { CustomButton } from '../../../components/custom.button'
import styles from './styles.module.scss'
import { CustomTextField } from '../../../components/custom.text.input'
import { validateEmail } from '../../../helpers'
import { type IUserData } from '../../../types'
import { updateUser } from '../../../helpers/api'
import toast from 'react-hot-toast'
import { useAppStore } from '../../../storage'

type FiledsType =
  | 'firstName'
  | 'lastName'
  | 'country'
  | 'state'
  | 'password'
  | 'email'
  | 'confirm_password'

// eslint-disable-next-line @typescript-eslint/member-delimiter-style
const UserForm: React.FC<{ userData?: IUserData; imgFile: any }> = ({
  userData,
  imgFile
}) => {
  const handleSubmit: () => void = () => {
    console.log('A: handleSubmit')
  }
  const [formValues, setFormValues] = React.useState({
    firstName: {
      value: userData?.firstname ?? '',
      error: false,
      errorMessage: 'You must enter a name'
    },
    lastName: {
      value: userData?.lastname ?? '',
      error: false,
      errorMessage: 'You must enter a name'
    },
    country: {
      value: userData?.country ?? '',
      error: false,
      errorMessage: 'You must enter a country'
    },
    state: {
      value: userData?.state ?? '',
      error: false,
      errorMessage: 'You must enter a state'
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    },
    email: {
      value: userData?.email ?? '',
      error: false,
      errorMessage: 'You must enter an email'
    },
    confirm_password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    }
  })

  const handleIputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: FiledsType
  ): void => {
    const { value } = e.target
    // if (['password', 'name'].includes(name)) {
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
    // }
  }
  const validateField = (name: FiledsType, value: string): boolean => {
    let error = false
    let errorMessage = ''
    switch (name) {
      case 'firstName':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a name'
        }
        break
      case 'password':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a password'
        }
        break
      case 'country':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a country'
        }
        break
      case 'state':
        if (value === '') {
          error = true
          errorMessage = 'You must enter a state'
        }
        break
      case 'email':
        if (value === '') {
          error = true
          errorMessage = 'You must enter an email'
        } else if (!validateEmail(value)) {
          error = true
          errorMessage = 'Email is incorrect'
        }
        break
      case 'confirm_password':
        if (value !== formValues.password.value) {
          error = true
          errorMessage = "Passwords doesn't match"
        }
        break

      default:
        break
    }
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        error,
        errorMessage
      }
    })

    return error
  }
  const handleFocusOut = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: FiledsType
  ): void => {
    const { value } = e.target
    validateField(name, value)
  }
  const handleInfoChange = (): void => {
    if (
      formValues.email.value &&
      formValues.password.value === formValues.confirm_password.value
    ) {
      // api auth call now faked
      updateUser({
        firstname: formValues.firstName.value ?? undefined,
        lastname: formValues.lastName.value ?? undefined,
        email: formValues.email.value ?? undefined,
        country: formValues.country.value ?? undefined,
        state: formValues.state.value ?? undefined,
        password: formValues.password.value ?? undefined,
        avatarFile: imgFile ?? undefined
      })
        .then((data) => {
          toast.success('User info changed!')
          useAppStore.setState({ userData: data.data.data })
        })
        .catch((e) => toast.error(e))
    }
    else {
      toast.error('Passwords do not match')
    }
  }

  React.useEffect(() => {
    if (userData) {
      setFormValues({
        firstName: {
          value: userData?.firstname ?? '',
          error: false,
          errorMessage: 'You must enter a name'
        },
        lastName: {
          value: userData?.lastname ?? '',
          error: false,
          errorMessage: 'You must enter a name'
        },
        country: {
          value: userData?.country ?? '',
          error: false,
          errorMessage: 'You must enter a country'
        },
        state: {
          value: userData?.state ?? '',
          error: false,
          errorMessage: 'You must enter a state'
        },
        password: {
          value: '',
          error: false,
          errorMessage: 'You must enter a password'
        },
        email: {
          value: userData?.email ?? '',
          error: false,
          errorMessage: 'You must enter an email'
        },
        confirm_password: {
          value: '',
          error: false,
          errorMessage: 'You must enter a password'
        }
      })
    }
  }, [userData])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1 }}
      className={styles.login_form}
    >
      <Grid className={styles.fields}>
        <CustomTextField
          name="firstname"
          required
          fullWidth
          id="firstName"
          label="First Name"
          autoFocus
          // color="primary"
          value={formValues.firstName.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/User.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
          error={formValues.firstName.error}
          helperText={formValues.firstName.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'firstName')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'firstName')
          }}
        />
        <CustomTextField
          name="lastname"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          autoFocus
          value={formValues.lastName.value}
          // color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/User.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
          error={formValues.lastName.error}
          helperText={formValues.lastName.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'lastName')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'lastName')
          }}
        />
        <CustomTextField
          name="country"
          required
          fullWidth
          id="country"
          label="Country"
          value={formValues.country.value}
          autoFocus
          // color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/User.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
          error={formValues.country.error}
          helperText={formValues.country.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'country')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'country')
          }}
        />
        <CustomTextField
          name="state"
          required
          fullWidth
          id="state"
          label="State"
          value={formValues.state.value}
          // color="primary"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/User.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
          error={formValues.state.error}
          helperText={formValues.state.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'state')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'state')
          }}
        />
        <CustomTextField
          name="email"
          required
          fullWidth
          id="email"
          label="Email"
          autoFocus
          // color="primary"
          value={formValues.email.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/User.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
          error={formValues.email.error}
          helperText={formValues.email.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'email')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'email')
          }}
        />
        <CustomTextField
          name="password"
          label="Password"
          type="password"
          id="password"
          required
          fullWidth
          error={formValues.password.error}
          helperText={formValues.password.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'password')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'password')
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/Key 5.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
        />
        <CustomTextField
          name="confirm_password"
          label="Confirm password"
          type="password"
          id="confirm_password"
          required
          fullWidth
          error={formValues.password.error}
          helperText={formValues.password.errorMessage}
          onChange={(e) => {
            handleIputChange(e, 'confirm_password')
          }}
          onBlur={(e) => {
            handleFocusOut(e, 'confirm_password')
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* <AccountCircle /> */}
                <img src="/assets/images/Key 5.svg" />
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      </Grid>
      <Grid className={styles.buttons}>
        <CustomButton
          onClick={handleInfoChange}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save information
        </CustomButton>
      </Grid>
    </Box>
  )
}

export default UserForm
