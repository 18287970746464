import * as React from 'react'
import { type IUserData } from '../../../types'
import DashboardPanel from './panel'
import DashboardContent from '../content'
import { Grid } from '@mui/material'
import styles from './styles.module.scss'
import { useAppStore } from '../../../storage'

interface IDashboardBodyProps {
  user: IUserData
}

const DashboardBody: React.FC<IDashboardBodyProps> = ({ user }) => {
  const [activeTab, setActiveTab] = React.useState<null | number>(null)
  const { activeDashboardTab } = useAppStore()
  const handleTabClick = (tabIndex: number): void => {
    setActiveTab(tabIndex)

    useAppStore.setState({ activeDashboardTab: tabIndex })
  }
  React.useEffect(() => {
    if (activeDashboardTab !== activeTab) {
      setActiveTab(activeDashboardTab)
    }
  }, [activeDashboardTab])
  return (
    <Grid
      container
      direction={'column'}
      className={`${styles.dashboard_body} ${
        activeDashboardTab === 4 ? styles.simulation : ''
      }`}
    >
      <DashboardPanel user={user} />
      <DashboardContent user={user} />
      <ul className={styles.bottom_menu}>
        <li
          className={`${styles.bottom_menu_item} ${
            activeTab === 0 ? styles.active : ''
          }`}
          onClick={() => {
            handleTabClick(0)
          }}
        >
          <img className={styles.icon} src="/assets/images/Account.svg" />
          <div className={styles.label}>Account</div>
        </li>
        <li
          className={`${styles.bottom_menu_item} ${
            activeTab === 1 ? styles.active : ''
          }`}
          onClick={() => {
            handleTabClick(1)
          }}
        >
          <img className={styles.icon} src="/assets/images/holdings.svg" />
          <div className={styles.label}>Holdings</div>
        </li>
        <li
          className={`${styles.bottom_menu_item} ${
            activeTab === 2 ? styles.active : ''
          }`}
          onClick={() => {
            handleTabClick(2)
          }}
        >
          <img className={styles.icon} src="/assets/images/noteavailable.svg" />
          <div className={styles.label}>Available</div>
        </li>
        <li
          className={`${styles.bottom_menu_item} ${
            activeTab === 3 ? styles.active : ''
          }`}
          onClick={() => {
            handleTabClick(3)
          }}
        >
          <img
            className={styles.icon}
            src="/assets/images/CurrencyCircleDollar.svg"
          />
          <div className={styles.label}>MDF</div>
        </li>
        <li
          className={`${styles.bottom_menu_item} ${
            activeTab === 4 ? styles.active : ''
          }`}
          onClick={() => {
            handleTabClick(4)
          }}
        >
          <img className={styles.icon} src="/assets/images/Arrow.svg" />
          <div className={styles.label}>Simulate</div>
        </li>
      </ul>
    </Grid>
  )
}

export default DashboardBody
