import { Grid } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import { type IUser } from '../types'
import { Outlet } from 'react-router-dom'
// import LogoBackground from '../components/logo.background'

interface LayoutProps {
  user?: IUser
}

const LoginLayout: React.FC<LayoutProps> = () => {
  return (
    <Grid className={styles.loginLayot} style={{ height: '100%' }}>
      {/* <LogoBackground /> */}
      <Outlet />
    </Grid>
  )
}

export default LoginLayout
