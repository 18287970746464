import { Grid, IconButton } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import { type IUserData } from '../../types'
import DashboardDrawer from './drawer'
import DashboardBody from './body'
import MenuIcon from '@mui/icons-material/Menu'
import { useAppStore } from '../../storage'

interface LayoutProps {
  user: IUserData
}

const DashboardLayout: React.FC<LayoutProps> = ({ user }) => {
  const { drawerOpen } = useAppStore()
  return (
    <Grid
      className={styles.dashboardLayout}
      style={{
        backgroundImage: 'url(assets/images/Background_spots.png)',
        backgroundSize: 'cover'
      }}
    >
      <IconButton
        className={styles.menu_button}
        onClick={() => {
          useAppStore.setState({ drawerOpen: !drawerOpen })
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <MenuIcon className={styles.menu_icon} color="white" />
      </IconButton>
      <DashboardDrawer user={user} />
      <DashboardBody user={user} />
    </Grid>
  )
}

export default DashboardLayout
