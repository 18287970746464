export const TRANSACTION_COLORS = [
  {
    primary: 'rgba(40, 161, 133, 1)'
  },
  { primary: 'rgba(180, 52, 103, 1)' },
  {
    primary: 'rgba(40, 161, 133, 1)'
  },
  { primary: 'rgba(180, 52, 103, 1)' }
]
