import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
// import { red, green, blue } from '@mui/material/colors'

export const CustomTextField = styled(TextField)(({ theme }) => ({
  // padding: theme.spacing(1),
  // [theme.breakpoints.down('md')]: {
  //   backgroundColor: red[500]
  // },
  // [theme.breakpoints.up('md')]: {
  //   backgroundColor: blue[500]
  // },
  // [theme.breakpoints.up('lg')]: {
  //   backgroundColor: green[500]
  // }

  '.MuiInputBase-input': {
    color: 'white',
    fontSize: '1vw',
    // lineHeight: '1.3em',
    margin: '0.4em 0 0.3em',
    fontFamily: 'EB Garamond'
  },
  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: '1.3em',
    // lineHeight: '1.3em',
    fontFamily: 'EB Garamond'
  },
  '.MuiInput-underline:after': {
    borderBottomColor: '#3A35C5',
    borderWidth: '3px'
  },
  '.MuiInput-root:before': {
    borderBottomColor: '#2f44bd',
    borderWidth: '3px'
  },
  '.MuiInput-underline:hover:after': {
    borderBottomColor: '#3A35C5',
    opacity: 0.7
  },
  '.MuiInput-root:hover:before': {
    borderBottomColor: '#2f44bd',
    opacity: 0.5
  },
  '.MuiFormHelperText-root': {
    color: 'rgba(0, 0, 0, 0)',
    fontSize: '0.7vw'
  },
  '@media screen and (max-width: 600px)': {
    '.MuiInputBase-input': {
      color: 'white',
      fontSize: '0.8em',
      lineHeight: '0.8m',
      margin: '0.4em 0 0.3em',
      fontFamily: 'EB Garamond'
    },
    '.MuiInputLabel-root': {
      color: 'white',
      fontSize: '2em',
      lineHeight: '2em',
      fontFamily: 'EB Garamond'
    },
    img: {
      width: '1em'
    },
    '.MuiError': {
      fontSize: '0.6em'
    },
    '.MuiFormHelperText-root': {
      color: 'rgba(0, 0, 0, 0)',
      fontSize: '2vw'
    }
  }
})) as typeof TextField
