import * as React from 'react'
import styles from './styles.module.scss'
import { Box, Grid, type SelectChangeEvent, Typography } from '@mui/material'
import { type ITransaction, type IUserData } from '../../types'
import CustomSelect from '../../components/custom.select'
import CustomChart, {
  type IGraphItemData
  // IGraphTooltipData,
  // type IGraphData
} from '../../components/chart'
import { useAppStore } from '../../storage'
// import { getTransactions } from '../../helpers/api'
import moment from 'moment'
import { getTransactionSignChar } from '../../helpers'
import Transactions from './transactions'

// import Transactions from './transactions'

// interface ITransactionsData {
//   graphTransactions
// }

const SECURITIES_CHART_OPTIONS = ['Last Year']

interface IAccountTabProps {
  user: IUserData
}

const AccountTab: React.FC<IAccountTabProps> = ({ user }) => {
  const { transactionsData } = useAppStore()
  const [securitiesSelectItem, setSecuritiesSelectItem] =
    React.useState<number>(0)
  const [viewVariant, setViewVariant] = React.useState<number>(0)
  const [graphData, setGraphData] = React.useState<any>(null)
  const [transactions, setTransaction] = React.useState<ITransaction[]>([])
  // const [graphData, setGraphData] = React.useState<any>({
  //   labels: [] as string[],
  //   dataDraw: [] as IGraphItemData[]
  // })
  // const [graphTooltipData, setGraphTooltipData] = React.useState<
  //   IGraphTooltipData[]
  // >([])

  const [
    statsToDraw
    // , setStatsToDraw
  ] = React.useState<{
    color: string
    value: string
    year: string
  } | null>(null)

  const handleInputChange = (e: SelectChangeEvent<unknown>): void => {
    setSecuritiesSelectItem(e.target.value as number)
  }
  const handleVariantInputChange = (e: SelectChangeEvent<unknown>): void => {
    setViewVariant(e.target.value as number)
  }

  const getTransactionsData = async (transactionsData: any): Promise<void> => {
    const data = transactionsData
    const dataDraw: IGraphItemData[] = []
    const _transactions: ITransaction[] = []
    // const labels: string[] = []
    // for (let k = this.transactions.length - 1; k >= 0; k--) {
    //     let t = this.transactions[k];
    //     t.timestamp = Helpers.convertISOToDateTime(t.created_at);
    //     console.log('transaction timestamp: ' + t.timestamp);
    // }
    console.log('transactions: ', transactionsData)
    for (let i = data.length - 1; i >= 0; i--) {
      // labels.push(
      //   moment(data.graphTransactions[i].created_at).format('MMM')
      // )
      // dataDraw.push(
      //   parseFloat(data.graphTransactions[i].ending_balance) / 10000 // .toFixed(2)
      // )
      dataDraw.push({
        label: moment(data[i].created_at).format('MMM'),
        value: parseFloat(data[i].ending_balance) / 10000,
        date: moment(data[i].created_at).format(
          'DD MMM, h:mm:ssA'
        ),
        profit:
          getTransactionSignChar(data[i]) +
          (
            (data[i].amount * 100) /
            data[i].ending_balance
          ).toFixed(2) +
          '%'
      })

      // const transacitonSign = getTransactionSign(data.transactions.data[i])
      // const transactionResultValue =
      //   data.transactions.data[i].ending_balance +
      //   transacitonSign * data.transactions.data[i].amount
      // const difference = Math.abs(
      //   data.graphTransactions[i].ending_balance - transactionResultValue
      // )

      _transactions.push({
        type: data.operation,
        time: moment(data[i].created_at).format(
          'MM.DD.yyyy hh:mm'
        ),
        value: data[i].ending_balance / 10000,
        difference:
          getTransactionSignChar(data[i]) +
          '$' +
          (data[i].amount / 10000).toFixed(2),
        id: data[i].transaction_id,
        name: data[i].operation,
        target: data[i].target
      })
    }

    setTransaction(_transactions)
    setGraphData(dataDraw)
    // setGraphData(graphData)
    // getTransactions(accountID)
    //   .then((_data) => {

    //   })
    //   .catch((e) => {
    //     console.error(e)
    //   })
  }

  React.useEffect(() => {
    transactionsData && getTransactionsData(transactionsData)
    // const lastYear = user.account.securities.last_year

    // let value = '+'
    // let color = 'rgba(40, 161, 133, 1)'
    // if (lastYear.difference > 0) {
    //   value = '+' + lastYear.difference + '% more '
    // } else {
    //   value = '-' + lastYear.difference + '% less'
    //   color = '#B43467'
    // }

    // const year = ' in ' + lastYear.value.toString()
    // setStatsToDraw({
    //   color,
    //   value,
    //   year
    // })
  }, [transactionsData])

  return (
    <Grid
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      className={styles.account_tab_wrapper}
    >
      <Grid gridColumn="span 9" className={styles.securities}>
        <div className={styles.securities_wrapper}>
          <Grid className={styles.header}>
            <Grid className={styles.labels}>
              <Typography className={styles.title}>Securities</Typography>
              {statsToDraw ? (
                <Box component="span" className={styles.stats}>
                  <Typography
                    className={styles.difference}
                    color={statsToDraw.color}
                    display="inline"
                  >
                    {statsToDraw.value}
                  </Typography>
                  <Typography display="inline" className={styles.year}>
                    {statsToDraw.year}
                  </Typography>
                </Box>
              ) : null}
            </Grid>
            <CustomSelect
              value={securitiesSelectItem}
              defaulValue={'Last Year'}
              list={SECURITIES_CHART_OPTIONS}
              onChangeCallback={handleInputChange}
            />
          </Grid>
          <div className={styles.custom_select_768}>
            <CustomSelect
              variant={2}
              value={viewVariant}
              defaulValue={'Transactions'}
              list={['Transactions', 'Chart']}
              onChangeCallback={handleVariantInputChange}
            />
          </div>
          <Grid
            className={`${styles.chart} ${
              viewVariant === 1 ? styles.active : ''
            }`}
          >
            <CustomChart data={graphData} />
          </Grid>
        </div>
      </Grid>
      <Grid
        gridColumn="span 3"
        className={`${styles.transactions_wrapper} ${
          viewVariant === 0 ? styles.active : ''
        }`}
      >
        <div className={styles.transactions}>
          <Transactions transactions={{ transactions }} />
        </div>
      </Grid>
    </Grid>
  )
}

export default AccountTab
