import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import { ROUTES } from '../app.router/router.constants'
import LoginPage from '../pages/login'
import SignUpPage from '../pages/login/signup'
import { useAppStore } from '../storage'
import DashboardLayout from '../layouts/dashboard'
import ProfilePage from '../pages/profile'
import ContentLayout from '../layouts/content.layout'
import LoginLayout from '../layouts/login.layout'

const PagesRoutes: React.FC = () => {
  const { userData } = useAppStore()

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.Login} element={<LoginLayout />}>
          <Route path={ROUTES.Login} element={<LoginPage />} />
          {/* <Route path={ROUTES.SignUp} element={<SignUpPage />} /> */}
        </Route>
        <Route
          element={
            userData ? (
              <ContentLayout />
            ) : (
              <Navigate replace to={ROUTES.Login} />
            )
          }
        // element={
        //   user ? (
        //     <DashboardLayout user={user} />
        //   ) : (
        //     <Navigate replace to={ROUTES.Login} />
        //   )
        // }
        >
          {/* <Route
            path={'/'}
            element={<Navigate replace to={ROUTES.Account} />}
          /> */}
          <Route
            path={ROUTES.Main}
            element={userData ? <DashboardLayout user={userData} /> : null}
          />
          <Route path={ROUTES.User} element={<ProfilePage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export { PagesRoutes }
