import { InputAdornment, TextField, styled } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
import SearchIcon from '@mui/icons-material/Search'

interface ITableSearchProps {
  onChangeCallback?: (changes: string) => void
  onSearchCallback: (value: string) => void
  helperText: string
}

export const CustomSearch = styled(TextField)(({ theme }) => ({
  background: 'linear-gradient(0deg, #131B4A 0%, #131B4A 100%)',
  padding: '0.8em',
  width: '361px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  '@media screen and (max-width: 800px)': {
    width: '100%'
  },
  '@media screen and (max-width: 600px)': {
    display: 'none'
  },
  '.MuiInputBase-input': {
    color: '#7980A9',
    fontSize: '1em',
    fontWeight: '500',
    lineHeight: 'normal',
    // margin: '14px 0',
    fontFamily: 'Lato',
    padding: 0
  },
  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: '1em',
    fontWeight: '500',
    lineHeight: 'normal',
    fontFamily: 'Lato',
    display: 'none'
  },
  '.MuiInputBase-root': {
    marginTop: '0px !important'
  },
  '.MuiInput-underline:after': {
    borderBottomColor: '#3A35C5',
    borderWidth: '3px',
    display: 'none'
  },
  '.MuiInput-root:before': {
    borderBottomColor: '#2f44bd',
    borderWidth: '3px',
    display: 'none'
  },
  '.MuiInput-underline:hover:after': {
    borderBottomColor: '#3A35C5',
    opacity: 0.7,
    display: 'none'
  },
  '.MuiInput-root:hover:before': {
    borderBottomColor: '#2f44bd',
    opacity: 0.5,
    display: 'none'
  }
}))

const TableSearch: React.FC<ITableSearchProps> = ({
  onSearchCallback,
  helperText
}) => {
  const [searchValue, setSearchValue] = React.useState('')
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { value } = e.target
    setSearchValue(value)
  }
  return (
    <CustomSearch
      className={styles.search}
      label={helperText}
      fullWidth
      onChange={(e) => {
        handleSearchChange(e)
      }}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        placeholder: helperText,
        endAdornment: (
          <InputAdornment
            position="start"
            sx={{
              height: '20px',
              color: 'white'
            }}
          >
            {/* <AccountCircle /> */}
            <SearchIcon
              onClick={() => {
                onSearchCallback(searchValue)
              }}
              sx={{
                cursor: 'pointer'
              }}
            />
          </InputAdornment>
        )
      }}
    />
  )
}

export default TableSearch
