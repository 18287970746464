import { Grid } from '@mui/material'
import * as React from 'react'
import styles from './styles.module.scss'
// import { type IUser } from '../types'
import { Outlet } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LayoutProps {
  // user: IUserData
}

const ContentLayout: React.FC<LayoutProps> = () => {
  return (
    <Grid className={styles.dashboardLayout} style={{ width: '100%' }}>
      <Outlet />
    </Grid>
  )
}

export default ContentLayout
