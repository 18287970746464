import * as React from 'react'
import { type ITransaction, type ITransactions } from '../../../types'
import {
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
  styled
} from '@mui/material'
import styles from './styles.module.scss'
import SearchIcon from '@mui/icons-material/Search'
// import { TRANSACTION_COLORS } from '../../../constants'
import { getTransationColor, getTransationIcon } from '../../../helpers'

interface ITransactionsProps {
  transactions: ITransactions
}

export const CustomSearch = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    color: 'white',
    fontSize: '1.4em',
    fontWeight: '500',
    lineHeight: 'normal',
    // margin: '14px 0',
    fontFamily: 'Lato'
  },
  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: '1.4em',
    fontWeight: '500',
    lineHeight: 'normal',
    fontFamily: 'Lato'
  },
  '.MuiInput-underline:after': {
    borderBottomColor: '#3A35C5',
    borderWidth: '3px',
    display: 'none'
  },
  '.MuiInput-root:before': {
    borderBottomColor: '#2f44bd',
    borderWidth: '3px',
    display: 'none'
  },
  '.MuiInput-underline:hover:after': {
    borderBottomColor: '#3A35C5',
    opacity: 0.7,
    display: 'none'
  },
  '.MuiInput-root:hover:before': {
    borderBottomColor: '#2f44bd',
    opacity: 0.5,
    display: 'none'
  },
  '.MuiInput-root': {
    // marginTop: 0
  }
}))

const TransactionElement: React.FC<{ transactions: ITransaction[] }> = ({
  transactions
}) => {
  const rows = transactions.map((item, index) => {
    const difference =
      // (item.type === 0 || item.type === 2 ? '+' : '-') +
      // '$' +
      item.difference.toString()

    const value = '$' + item.value.toFixed(2)
    return (
      <>
        {index ? (
          <Divider
            className={styles.divider}
            sx={{ borderColor: 'rgba(29, 41, 112, 1)' }}
          />
        ) : null}
        <ListItem key={index} value={index} className={styles.list_item}>
          <img
            src={getTransationIcon(item.type)}
            // src={`/assets/images/Transaction${item.type + 1}.svg`}
            className={styles.transaction_icon}
          />
          <Grid className={styles.name_time}>
            <Typography className={styles.name}>
              {item.name}
              {item.target ? ` Sec# ${item.target}` : ''}
            </Typography>
            <Typography className={styles.time}>{item.time}</Typography>
          </Grid>
          <Grid className={styles.values}>
            <Typography
              color={getTransationColor(item.type).primary}
              className={styles.difference}
            >
              {difference}
            </Typography>
            <Typography className={styles.value}>{value}</Typography>
          </Grid>
        </ListItem>
      </>
    )
  })
  return <>{rows}</>
}

const Transactions: React.FC<ITransactionsProps> = ({ transactions }) => {
  // const [transactionsToShow, setTransactionsToShow] =
  //   React.useState(transactions)
  const [searchValue, setSearchValue] = React.useState('')
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { value } = e.target
    setSearchValue(value)
  }

  const performSearch = (): void => {
    console.log('A: performSearch', searchValue)
  }

  const renderTransactions = (): JSX.Element[] => {
    const trArr: JSX.Element[] = []
    for (const [key, value] of Object.entries(transactions)) {
      trArr.push(
        <List className={styles.transactions_list}>
          {/* <Typography color={'white'} className={styles.transactions_date}>
            {key}
          </Typography> */}
          <TransactionElement transactions={value} key={key} />
        </List>
      )
    }
    return trArr
  }

  return (
    <>
      <Grid className={styles.search_wrapper}>
        <CustomSearch
          className={styles.search}
          label="Transactions"
          fullWidth
          onChange={(e) => {
            handleSearchChange(e)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="start"
                className={styles.custom_search_adorment}
              >
                {/* <AccountCircle /> */}
                <SearchIcon
                  onClick={performSearch}
                  className={styles.custom_search_icon}
                  sx={{
                    cursor: 'pointer'
                  }}
                />
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      </Grid>
      <Grid className={styles.transactions}>{renderTransactions()}</Grid>
    </>
  )
}

export default Transactions
