import * as React from 'react'
import styles from './styles.module.scss'
import {
  Grid,
  type SelectChangeEvent,
  Typography,
  // Button,
  IconButton,
  Popover,
  FormControlLabel,
  RadioGroup,
  Radio,
  type CircularProgressProps,
  Box,
  CircularProgress,
  Button
} from '@mui/material'
import { type IUserData } from '../../types'
import CustomTable from '../../components/custom.tabe'
import CustomSelect from '../../components/custom.select'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useAppStore } from '../../storage'
import { handleSimulate, handleSimulateAll } from '../holdings'
import toast from 'react-hot-toast'
import SimulationChart from '../../components/chart/SimulationChart'

const HEADER_LABELS = ['Total Value', 'Profit/Loss', 'Simulation History']

const HISTORY_TABLE_HEADERS = [
  'Sims Remaining',
  'Stock',
  'Put',
  'Straddle',
  'Total'
]

interface IAccountTabProps {
  user: IUserData
}

const CircularProgressWithLabel = (
  props: CircularProgressProps & { simRemains: number }
): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: '80px',
        height: '80px'
      }}
      className={styles.circular_progress_wrapper}
    >
      <CircularProgress
        sx={{
          color: (theme) => 'rgba(55, 52, 219, 0.94)'
        }}
        size={80}
        variant="determinate"
        {...props}
      />
      <Box
        className={styles.circular_label_wrapper}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          className={styles.remaining}
        >
          {props.simRemains}
        </Typography>
        <Typography
          className={styles.progress}
          variant="caption"
          component="div"
        >{`${Math.round(props.value ?? 0)}%`}</Typography>
      </Box>
    </Box>
  )
}

const SimulationTab: React.FC<IAccountTabProps> = ({ user }) => {
  // const { simulationActiveTab } = useAppStore()
  const [graphData1, setGraphData1] = React.useState<any>(null)
  const [graphData2, setGraphData2] = React.useState<any>(null)
  const [activeTab, setActiveTab] = React.useState(0)
  const [simDifference, setSimDifference] = React.useState<any>(undefined)

  const [securitiesSelectItem, setSecuritiesSelectItem] =
    React.useState<number>(0)

  const [tableCells1, setTableCells1] = React.useState<
    (JSX.Element | string | number)[][]
  >([])

  const {
    simulatedHolding,
    simulationData,
    simulationHistoryData,
    simulationGraphData,
    accountID
  } = useAppStore()

  const handleSimData = (): void => {
    if (simulatedHolding && simulationData) {
      setSimDifference({
        stock:
          simulationData.security.stock_price -
          simulatedHolding.security.stock_price,
        put: simulationData.security.put - simulatedHolding.security.put,
        straddle:
          simulationData.security.straddle - simulatedHolding.security.straddle,
        total:
          simulationData.security.total_price -
          simulatedHolding.security.total_price,
        simsRemaining: (
          (simulationData.sim_A.length * 100) /
          simulationData.security.initial_sim
        )
          // simsRemaining: (
          //   (simulationData.security.simulations * 100) /
          //   simulationData.security.initial_sim
          // )
          // old variant
          // (simulationData.security.simulations * 100) /
          // simulatedHolding.security.simulations
          .toFixed()
      })
      setActiveTab(0)
    }
  }
  const handleSimHistoryData = (): void => {
    if (simulationHistoryData && simulationData) {
      const cells: (JSX.Element | string | number)[][] = []
      simulationHistoryData.data.forEach((sh: any) => {
        const listRow: (JSX.Element | string | number)[] = []
        listRow.push(sh.sims_remaining)

        const stockDiff = sh.stock_price - simulatedHolding.security.stock_price
        const stockDiffColor =
          stockDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.stock_price / 10000}
            </Typography>
            <Typography color={stockDiffColor} className={styles.value2}>
              {`${stockDiff > 0 ? '+' : '-'}$${Math.abs(stockDiff / 10000)}`}
            </Typography>
          </Grid>
        )

        const putDiff = sh.put - simulatedHolding.security.put
        const putDiffColor =
          putDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>${sh.put / 10000}</Typography>
            <Typography color={putDiffColor} className={styles.value2}>
              {`${putDiff > 0 ? '+' : '-'}$${Math.abs(putDiff / 10000)}`}
            </Typography>
          </Grid>
        )

        const straddleDiff = sh.straddle - simulatedHolding.security.straddle
        const straddleDiffColor =
          straddleDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.straddle / 10000}
            </Typography>
            <Typography color={straddleDiffColor} className={styles.value2}>
              {`${straddleDiff > 0 ? '+' : '-'}$${Math.abs(
                straddleDiff / 10000
              )}`}
            </Typography>
          </Grid>
        )

        const totalDiff = sh.total_price - simulatedHolding.security.total_price
        const totalDiffColor =
          totalDiff > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
        listRow.push(
          <Grid className={styles.table_cell_values}>
            <Typography className={styles.value1}>
              ${sh.total_price / 10000}
            </Typography>
            <Typography color={totalDiffColor} className={styles.value2}>
              {`${totalDiff > 0 ? '+' : '-'}$${Math.abs(totalDiff / 10000)}`}
            </Typography>
          </Grid>
        )
        cells.push(listRow)
      })

      setTableCells1(cells)
    }
  }

  const handleSimGraphData = (): void => {
    // const dataDraw: ISimulationGraphItemData = []
    // const dataDraw2: ISimulationGraphItemData = []
    if (simulationGraphData && simulationData) {
      const dataInvestor: any[] = []
      // const diffArr: any[] = []
      const dataNvic: any[] = []
      const arrayLabelsA: any[] = []

      const dataInvestor2: any[] = []
      const dataNvic2: any[] = []
      const arrayLabelsB: any[] = []

      const stockChange = simulationGraphData.varStockA
        ? (simulationGraphData.varStockA / 10000.0).toFixed(2)
        : null
      // const putChange = simulationGraphData.varPutA
      //   ? (simulationGraphData.varPutA / 10000.0).toFixed(2)
      //   : null
      // const straddleChange = simulationGraphData.varStraddleA
      //   ? (simulationGraphData.varStraddleA / 10000.0).toFixed(2)
      //   : null

      console.log('Stock chage: ' + stockChange)
      let count1 = 0
      for (let i = 0; i < simulationGraphData.sim_A.length; i++) {
        dataInvestor.push(simulationGraphData.sim_A[i].total_price / 10000)
        dataInvestor2.push(
          dataInvestor[i] - simulationData.security.last_total_price / 10000
        )
        // difA = dataInvestor[i] - this.selected_security.last_total_price / 10000;
        arrayLabelsA.push(count1)

        // const value = simulationGraphData.sim_A[i].total_price

        // const currentTotalPrice = simulationData.security.last_total_price
        // const difference1 = currentTotalPrice - value
        // let diffPercentage = 0
        // let sign = '+'
        // if (difference1 > 0) {
        //   sign = '-'
        //   diffPercentage = (value * 100) / currentTotalPrice
        // } else {
        //   diffPercentage = (currentTotalPrice * 100) / value
        // }

        // dataDraw.push({
        //   label: count,
        //   value: value,
        //   profit: `${sign}${(diffPercentage / 10000).toFixed(5)}%`
        // })
        // dataDraw2.push({
        //   label: count,
        //   value: difference1 / 10000,
        //   profit: `${sign}${(diffPercentage / 10000).toFixed(5)}%`
        // })
        count1 = count1 + 1
      }
      let count2 = 0
      for (let i = 0; i < simulationGraphData.sim_B.length; i++) {
        dataNvic.push(
          (simulationGraphData.sim_B[i].total_price / 10000).toFixed(2)
        )
        dataNvic2.push(
          dataNvic[i] - simulationData.security.last_total_price / 10000
        )
        arrayLabelsB.push(count2)
        count2 = count2 + 1
      }

      setGraphData1({
        labels: arrayLabelsA,
        dataInvestor,
        dataNvic
      })
      setGraphData2({
        labels: arrayLabelsB,
        dataInvestor: dataInvestor2,
        dataNvic: dataNvic2
      })

      // this.profit(this.dataInvestor,this.dataNvic);

      // this.drawSimulationsGraph('', simulationGraphData.sim_A.length, this.selected_security.simulations);

      // this.drawGraphTotal("line-basic-chart-total",this.dataInvestor,this.dataNvic);
    }
  }

  React.useEffect(() => {
    // setTableCells1(user.mdf.monthly_list)
    // const cells: (JSX.Element | string | number)[][] = []
    // user.simulation.list.forEach((row) => {
    //   const listRow: (JSX.Element | string | number)[] = []
    //   row.forEach((cell, cellInd) => {
    //     if (cellInd === 0) {
    //       listRow.push(cell as number)
    //     } else {
    //       const value1Parsed = '$' + (cell as number[])[0].toString()

    //       const value2 = (cell as number[])[1]
    //       const value2Parsed = `${value2 > 0 ? '+' : '-'}` + value2
    //       const color =
    //         value2 > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
    //       listRow.push(
    //         <Grid className={styles.table_cell_values}>
    //           <Typography className={styles.value1}>{value1Parsed}</Typography>
    //           <Typography color={color} className={styles.value2}>
    //             {value2Parsed}
    //           </Typography>
    //         </Grid>
    //       )
    //     }
    //   })
    //   cells.push(listRow)
    // })
    // setTableCells1(cells)
    if (simulatedHolding) {
      handleSimData()
    }
  }, [simulatedHolding, simulationData])
  React.useEffect(() => {
    handleSimHistoryData()
  }, [simulatedHolding, simulationHistoryData])
  React.useEffect(() => {
    handleSimGraphData()
  }, [simulatedHolding, simulationGraphData])

  const handleInputChange = (e: SelectChangeEvent<unknown>): void => {
    setSecuritiesSelectItem(e.target.value as number)
  }

  const renderContent = (tab: number): JSX.Element | undefined => {
    switch (tab) {
      case 0:
        return <SimulationChart data={graphData1} />
      case 1:
        return <SimulationChart data={graphData2} />

      case 2:
        return (
          <CustomTable headers={HISTORY_TABLE_HEADERS} cells={tableCells1} />
        )
      default:
        break
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleDotsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const variatonOpen = Boolean(anchorEl)
  const simulationDotsId = variatonOpen ? 'simple-popover' : undefined

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(parseFloat((event.target as HTMLInputElement).value))
    setActiveTab(parseFloat((event.target as HTMLInputElement).value))
  }

  // const [activeSecurity] = React.useState(user.simulation.list[0])

  const renderColorStat = (value: number): JSX.Element => {
    const value2Parsed = `${value > 0 ? '+' : '-'}` + '$' + Math.abs(value)
    const color = value > 0 ? 'rgba(40, 161, 133, 1)' : 'rgba(180, 52, 103, 1)'
    return (
      <Typography color={color} className={styles.value2}>
        {value2Parsed}
      </Typography>
    )
  }

  const handleSimulateClick = (): void => {
    if (simulatedHolding) {
      if (simulatedHolding.simulations > 0) {
        handleSimulate({
          ...simulatedHolding,
          simulations: simulatedHolding.simulations - 1
        })
      } else {
        toast.error('No available simulations')
      }
    } else {
      toast.error('Holding is not chosen')
    }
  }
  const handleSimulateAllClick = (): void => {
    if (simulatedHolding) {
      if (simulatedHolding.simulations > 0) {
        handleSimulateAll(
          { ...simulatedHolding, simulations: 0 },
          {
            accountID,
            simsNumber: simulatedHolding.simulations
          }
        )
      } else {
        toast.error('No available simulations')
      }
    } else {
      toast.error('Holding is not chosen')
    }
  }

  return (
    <Grid
      display={'grid'}
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      className={styles.account_tab_wrapper}
    >
      <Grid className={styles.header_1024}>
        <Grid className={styles.header_labels}>
          <Typography className={styles.title}>
            {/* {HEADER_LABELS[activeTab]} */}
            {/* {'Sec#' + selectedSecurity} */}
          </Typography>
          <div className={styles.sims}>
            <Typography className={styles.sims_remaining}>
              Sims Remaining
            </Typography>
            <Typography className={styles.sims_value}>845</Typography>
          </div>
        </Grid>

        <Grid className={styles.header_buttons}>
          <IconButton
            aria-describedby={simulationDotsId}
            className={styles.dots_button}
            onClick={handleDotsClick}
          >
            <MoreHorizIcon className={styles.dots_icon} />
          </IconButton>
          <Popover
            id={simulationDotsId}
            open={variatonOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Grid className={styles.radio_buttons_wrapper}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  className={styles.radio_option}
                  value={0}
                  control={<Radio color="success" />}
                  label="Total value"
                />
                <FormControlLabel
                  className={styles.radio_option}
                  value={1}
                  control={<Radio color="success" />}
                  label="Profit/Loss"
                />
                <FormControlLabel
                  className={styles.radio_option}
                  value={2}
                  control={<Radio color="success" />}
                  label="Table"
                />
              </RadioGroup>
            </Grid>
          </Popover>
        </Grid>
      </Grid>
      <Grid gridColumn="span 9" className={styles.securities}>
        <div className={styles.securities_wrapper}>
          <Grid className={styles.header}>
            <Typography className={styles.title}>
              {HEADER_LABELS[activeTab]}
            </Typography>

            <Grid className={styles.header_buttons}>
              <CustomSelect
                value={securitiesSelectItem}
                defaulValue={'Last Year'}
                list={['Last Year', 'Thid Year']}
                onChangeCallback={handleInputChange}
              />
              <IconButton
                aria-describedby={simulationDotsId}
                className={styles.dots_button}
                onClick={handleDotsClick}
              >
                <MoreHorizIcon className={styles.dots_icon} />
              </IconButton>
              <Popover
                id={simulationDotsId}
                open={variatonOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Grid className={styles.radio_buttons_wrapper}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className={styles.radio_option}
                      value={0}
                      control={<Radio color="success" />}
                      label="Total value"
                    />
                    <FormControlLabel
                      className={styles.radio_option}
                      value={1}
                      control={<Radio color="success" />}
                      label="Profit/Loss"
                    />
                    <FormControlLabel
                      className={styles.radio_option}
                      value={2}
                      control={<Radio color="success" />}
                      label="Table"
                    />
                  </RadioGroup>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
          <Grid className={styles.body}>{renderContent(activeTab)}</Grid>
        </div>
      </Grid>
      <Grid gridColumn="span 3" className={styles.rankings_wrapper}>
        {simulationData && simDifference ? (
          <div className={styles.rankings}>
            <Grid className={styles.header}>
              <Typography className={styles.title}>Run Simulation</Typography>
              <span className={styles.security_label}>
                Security
                <span className={styles.security_number}>
                  {' '}
                  #{simulationData.security.security_number}
                </span>
              </span>
            </Grid>
            {/* <Grid className={styles.simulation_content}> */}
            <div className={styles.stats}>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Stock</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {/* ${(activeSecurity[1] as number[])[0]} */}
                    {'$' + simulationData.security.stock_price / 10000}
                  </Typography>
                  {/* {renderColorStat((activeSecurity[1] as number[])[1])} */}
                  {renderColorStat(simDifference.stock / 10000)}
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Put</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {/* ${(activeSecurity[2] as number[])[0]} */}
                    {'$' + simulationData.security.put / 10000}
                  </Typography>
                  {/* {renderColorStat((activeSecurity[2] as number[])[1])} */}
                  {renderColorStat(simDifference.put / 10000)}
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.pie1}>
                  <img src="/assets/images/IconPie.svg" />
                </div>
                <div className={styles.stat_title}>Straddle</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {/* ${(activeSecurity[3] as number[])[0]} */}
                    {'$' + simulationData.security.straddle / 10000}
                  </Typography>
                  {/* {renderColorStat((activeSecurity[3] as number[])[1])} */}
                  {renderColorStat(simDifference.straddle / 10000)}
                </div>
              </div>
              <div className={`${styles.stat} ${styles.total_stat}`}>
                <div className={`${styles.pie1} ${styles.pie1_1024}`}>
                  <img src="/assets/images/pie_2.svg" />
                </div>
                <div className={styles.stat_title}>Total</div>
                <div className={styles.stat_values}>
                  <Typography className={styles.value1}>
                    {/* ${(activeSecurity[4] as number[])[0]} */}
                    {'$' + simulationData.security.total_price / 10000}
                  </Typography>
                  {/* {renderColorStat((activeSecurity[4] as number[])[1])} */}
                  {renderColorStat(simDifference.total / 10000)}
                </div>
              </div>
            </div>
            <div className={styles.simulations_remaining}>
              <CircularProgressWithLabel
                value={simDifference.simsRemaining}
                // simRemains={simulationData?.sim_A?.length || 0}
                simRemains={
                  simulationData.security.initial_sim -
                  simulationData.security.simulations
                }
              />
              <div className={styles.chart_legend}>
                <div className={styles.color}></div>
                <Typography className={styles.label4}>
                  Simulations <br></br> Remaining
                </Typography>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                className={`${styles.simulate_button}`}
                startIcon={
                  <img
                    className={styles.simulate_button_icon}
                    src="/assets/images/Arrow.svg"
                  />
                }
                onClick={handleSimulateClick}
              >
                Simulate
              </Button>
              <Button
                className={`${styles.simulate_button}`}
                startIcon={
                  <img
                    className={styles.simulate_button_icon}
                    src="/assets/images/Arrow.svg"
                  />
                }
                onClick={handleSimulateAllClick}
              >
                Simulate All
              </Button>
            </div>
            {/* </Grid> */}
          </div>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default SimulationTab
