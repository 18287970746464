import React, { useEffect, useState } from 'react'
import './app.modules.scss'

import { ThemeProvider, createTheme } from '@mui/material'
import { Toaster } from 'react-hot-toast'
import { initializeApp } from './helpers/app'
import MainLayout from './layouts'
// import axios from 'axios'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#3A35C5'
    }
  }
})

const App: React.FC = () => {
  const [readyToShow, setReadyToShow] = useState<boolean>(false)

  useEffect(() => {
    // void axios
    //   .get('https://jsonplaceholder.typicode.com/todos/1')
    //   // .then((response) => response.json())
    //   .then((json) => {
    //   })
    void initializeApp().then(() => {
      setReadyToShow(true)
    })
  }, [])
  return (
    <div className="App">
      {/* <StyledEngineProvider injectFirst> */}
      <Toaster />
      <ThemeProvider theme={theme}>
        {readyToShow ? <MainLayout /> : null}
      </ThemeProvider>
      {/* </StyledEngineProvider> */}
    </div>
  )
}

export default App
