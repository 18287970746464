import { Grid } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss'

const LogoBackground: React.FC = () => {
  return (
    <Grid
      style={{
        // background: 'red',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url(assets/images/Background.svg)',
        backgroundSize: 'cover',
        overflow: 'hidden',
        zIndex: 0,
        pointerEvents: 'none'
      }}
      className={styles.logo_bg_wrapper}
    >
      <img
        // className="centered"
        style={{ mixBlendMode: 'overlay', width: '100%' }}
        src={'/assets/images/Logo_bg.png'}
      />
    </Grid>
  )
}

export default LogoBackground
