const ROUTES = {
  Account: '/account',
  Holdings: '/holdings',
  Available: '/available',
  MDF: '/mdf',
  Login: '/login',
  SignUp: '/login/signUp',
  User: '/user',
  Main: '/'
}

export { ROUTES }
