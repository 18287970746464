import * as React from 'react'
import { type IUserData } from '../../types'
import { Button, Grid, Typography } from '@mui/material'
import styles from './styles.module.scss'
import TableSearch from '../../components/table.search'
import CustomTable from '../../components/custom.tabe'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useAppStore } from '../../storage'
import { buySecurity, getSecurities } from '../../helpers/api'
import toast from 'react-hot-toast'

interface IHoldingsTabProps {
  user: IUserData
}

const TABLE_HEADERS = [
  'Security',
  'Sims',
  'Market Price',
  'Days to Maturity',
  'Expected Value',
  ''
]
const TABLE_HEADERS_MOBILE = ['Security', 'Sims', 'Market Price', '']

export const securitiesRequest = (callback: any): void => {
  getSecurities()
    .then((data) => {
      console.log('securities: ', data)
      useAppStore.setState({ securitiesData: data.data })
      if (callback) {
        callback()
      }
    })
    .catch((e) => {
      console.error(e)
    })
}

const HoldingsActionButton: React.FC<{
  // securityID: number
  // holdingID?: number
  // securityNumber?: number,
  security: number
  accountID: any
}> = ({ security, accountID }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    console.log('A: buy', security)
    toast
      .promise(buySecurity(security, accountID), {
        loading: 'Process...',
        success: (data) => {
          if (data?.data.errors?.length) throw new Error(data?.data.errors[0])
          else securitiesRequest(null)
          return 'Success!'
        },
        error: (err) => err.message
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <>
      <Button
        id={'action_button' + security.toString()}
        aria-controls={open ? 'action_menu' + security.toString() : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        className={styles.user_button}
        startIcon={
          <img
            className={styles.user_button_icon}
            src="/assets/images/buy_icon.svg"
          />
        }
        sx={{
          '& .MuiButton-startIcon': {
            marginRight: '0.6em',
            marginLeft: '0px'
          }
        }}
      >
        Buy
      </Button>
    </>
  )
}

const AvailableTab: React.FC<IHoldingsTabProps> = ({ user }) => {
  const { securitiesData, accountID } = useAppStore()
  const handleSearch = (value: string): void => {
    console.log('A: handleSearch', value)
  }
  const tableVariant = useMediaQuery('(min-width:600px)')

  const [tableCells, setTableCells] = React.useState<
    (JSX.Element | string | number)[][] | null
  >(null)

  const getSecuritiesData = async (): Promise<void> => {
    const data = securitiesData.data
    // const cells: (JSX.Element | string | number)[][] = []

    const tableHoldings: any[] = []

    data.forEach((security: any) => {
      let row: any[] = []
      row.push(security.security_number)
      row.push(security.simulations)
      row.push('$' + security.total_price / 10000)
      row.push(security.maturity_date)
      row.push('$' + security.expected_value / 10000)
      if (!tableVariant) {
        row = row.splice(row.length - 3)
      }
      row.push(
        <HoldingsActionButton
          security={security.id}
          accountID={accountID}
          // holdingID={holding.id}
        />
      )
      tableHoldings.push(row)
    })
    // user.holdings.list.forEach((el) => {
    //   let row = Object.values(el)
    //   if (!tableVariant) {
    //     row = row.splice(row.length - 3)
    //   }
    //   row.push(<HoldingsActionButton security={el.security} />)
    //   cells.push(row)
    // })
    setTableCells(tableHoldings)

    // getHoldings()
    //   .then((_data) => {

    //   })
    //   .catch((e) => {
    //     console.error(e)
    //   })
  }

  React.useEffect(() => {
    if (securitiesData) {
      getSecuritiesData().then(() => {}).catch((err) => {
        console.error(err)
      })
    }
    else {
      securitiesRequest(null)
    }
  }, [tableVariant, securitiesData])

  return (
    <Grid className={styles.holdings_tab} direction={'column'}>
      <div className={styles.holdings_wrapper}>
        <Grid className={styles.header}>
          <Typography className={styles.name}>
            Available for Purchase
          </Typography>
          <TableSearch
            onSearchCallback={handleSearch}
            helperText={'Search for...'}
          />
        </Grid>
        {tableCells ? (
          <CustomTable
            headers={tableVariant ? TABLE_HEADERS : TABLE_HEADERS_MOBILE}
            cells={tableCells}
          />
        ) : null}
      </div>
    </Grid>
  )
}

export default AvailableTab
